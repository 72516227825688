<template>
  <div>
    <d-body-top-bar
      :title="$t('views.client.your-customers')"
      :class="$store.getters['layout/getInnerWidth'] < 1332 ? 'mt-3' : ''"
    >
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-card>
        <b-row class="row-style" no-gutters>
          <b-col class="mt-2 ml-3" :cols="getTopNavMenuCol()">
            <d-body-nav-bar
              :tabs="$store.getters['menu/getTabs'].customerList"
              @tab-changed="onTabChanged"
            />
          </b-col>
          <b-col
            align="right"
            class="d-flex align-items-center justify-content-end"
          >
            <d-button
              :text="$store.getters['layout/getInnerWidth'] < 600 ? 'views.client.new-add' : 'views.client.index.new.default'"
              :class="buttonWidth"
              class="d-btn btn d-btn-primary font-text-title mr-2"
              icon="fa fa-user-plus mr-1"
              @on:button-click="$router.push({ name: 'customer_new' })"
            />
            <export-csv
              v-if="selectedTab !== 'views.client.index.subscribed'"
              target="clients"
              label="components.custom.export-csv.export-all-customers"
              class="mr-2"
            />
            <export-csv
              v-if="selectedTab === 'views.client.index.subscribed'"
              target="subscribers"
              label="components.custom.export-csv.export-all-subscribers"
              class="mr-2"
            />
            <div class="has-search mr-2">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                v-model="filter"
                type="text"
                class="form-control"
                @keyup="manualFilter"
                :placeholder="$store.getters['layout/getInnerWidth'] < 600 ? '' : $t('components.doinsport.table.search')"
              >
            </div>
          </b-col>
        </b-row>
        <d-table
          identifier="customer"
          :tablefields="tableFields"
          :items="items"
          :totalRows="totalRows"
          :per-page="itemsPerPage"
          :is-busy="isBusy"
          :tr-cursor-pointer="true"
          :per-page-enabled="true"
          @on:sort-by:item="onSort"
          @row-clicked="onRowClicked"
          @per-page:update="onPerPageUpdate"
          @on:customer:delete="onCustomerDelete"
          @current-page:update="onCurrentPageUpdate"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  getClubClients,
  deleteClubClient,
} from "@/api/doinsport/services/client/client.api";
import {SUCCESS} from "@plugins/flash";

const onEmptyFields = '-';

export default {
  components: {
    ExportCsv: () => import('@custom/ExportCsv'),
  },
  data: () => ({
    filter: '',
    lastOrder: null,
    timer: null,
    isExporting: false,
    filters: '',
    isBusy: true,
    visible: false,
    items: [],
    itemsPerPage: 10,
    selectedTab: null,
    currentPage: 1,
    totalRows: 1,
    hide: true,
    show: true,
    clientsList: [],
  }),

  computed: {
    buttonWidth() {
      if (this.$store.getters['layout/getInnerWidth'] < 600 && this.$store.getters['layout/getInnerWidth'] > 400) {
        return 'pr-4 pl-4'
      } else if (this.$store.getters['layout/getInnerWidth'] <= 400) {
        return 'pr-3 pl-3'
      } else {
        return 'pr-5 pl-5'
      }
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields() {
      const allFields = [
        {key: "preview", class: 'no', label: '', sortable: false},
        {key: "firstName", label: this.$t("views.client.index.firstName"), sortable: true},
        {key: "lastName", label: this.$t("views.client.index.lastName"), sortable: true},
        {key: "phoneNumber", label: this.$t("views.client.index.phoneNumber"), sortable: true},
        {key: "email", label: this.$t("views.client.index.email"), sortable: true},
        {key: "bookings", class: this.alignCellsToLeft, label: this.$t("views.client.index.bookings"), sortable: false},
        {
          key: "customerSubscription",
          class: this.alignCellsToLeft,
          label: this.$t("views.client.index.subscription"),
          sortable: false
        },
        {key: "credits", class: this.alignCellsToLeft, label: this.$t("views.client.index.credits"), sortable: false},
        {key: "created", label: this.$t("views.client.index.createdAt"), sortable: true},
        {key: "actions", label: this.$t('general.actions.actions')},
      ]

      const newFields = allFields.slice(1, 4);
      const mobileFields = [
        ...newFields,
        {key: "actions", label: this.$t('general.actions.actions')},
      ];

      if (this.selectedTab !== 'views.client.index.subscribed') {
        return this.$store.getters['layout/getInnerWidth'] < 900 ? mobileFields : allFields;
      } else {
        const subscribedListFields = [
          {key: "preview", class: 'no', label: '', sortable: false},
          {key: "firstName", label: this.$t("views.client.index.firstName"), sortable: true},
          {key: "lastName", label: this.$t("views.client.index.lastName"), sortable: true},
          {key: "phoneNumber", label: this.$t("views.client.index.phoneNumber"), sortable: true},
          {key: "email", label: this.$t("views.client.index.email"), sortable: true},
          {
            key: "customerSubscription",
            class: this.alignCellsToLeft,
            label: this.$t("views.client.index.subscription"),
            sortable: false
          },
          {key: "earliestStartAtSubscription", label: this.$t("views.client.index.earliest"), sortable: true},
          {key: "latestEndAtSubscription", label: this.$t("views.client.index.latest"), sortable: true},
          {key: "actions", label: this.$t('general.actions.actions')}
        ]

        const customList = subscribedListFields.slice(1, 4);

        const mobileList = [
          ...customList,
          {key: "actions", label: this.$t('general.actions.actions')},
        ];

        return this.$store.getters['layout/getInnerWidth'] < 900 ? mobileList : subscribedListFields;
      }
    }
  },
  mounted() {
    const selectedTab = this.$store.getters['client/getSelectedTab'];

    if (selectedTab === null) {
      for (const menu of this.$store.getters['menu/getTabs'].customerList) {
        menu.active = menu.name === 'views.client.index.all';
      }
    }
    this.onTabChanged({name: selectedTab});
  },
  methods: {
    onTabChanged(scope) {
      this.selectedTab = scope.name;

      this.$store.commit('client/setSelectedTab', scope.name);

      switch (this.selectedTab) {
        case 'views.client.index.subscribed':
          this.filters = '&subscriptionCards.available=true';
          break;
        case 'views.client.index.credits':
          this.filters = '&paymentToken.available=true';
          break;
        default:
          this.filters = '';
      }

      this.loadClients(this.itemsPerPage, this.currentPage, '');
    },
    onSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;
        this.loadClients(this.itemsPerPage, this.currentPage, '');
      }
    },
    getFilters() {
      let sortBy = '';
      switch (this.lastOrder.sortBy) {
        case 'created':
          sortBy = 'createdAt';
          break;
        case 'earliestStartAtSubscription':
          sortBy = 'subscriptionCards.startDate';
          break;
        case 'latestEndAtSubscription':
          sortBy = 'subscriptionCards.endDate';
          break;
        default:
          sortBy = this.lastOrder.sortBy;
      }

      return this.lastOrder.sortDesc ? '&order[' + sortBy + ']=desc' : '&order[' + sortBy + ']=asc';
    },
    onCustomerDelete(customer) {
      const message = this.$t('views.client.customer-label') + ' ' + customer.firstName + ' ' + customer.lastName + ' ';

      deleteClubClient(customer.id)
        .then(() => {
          this.$flash(null, `${message} ${this.$t('views.client.customer-deleted')}`, 5000, SUCCESS);
          this.loadClients(this.itemsPerPage, this.currentPage, '');
        })
        .catch((err) => {
          this.$flash(null, `${message} ${this.$t('views.client.cannot-be-deleted')}`);
        })
      ;
    },
    getColsClasses() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-0';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '6';
      }
    },
    getNewCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 724) {
        return '4';
      } else {
        return '6';
      }
    },
    getBtnsCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '2';
      } else {
        return '6';
      }
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search(this.filter);
      }, 400);
    },
    onCurrentPageUpdate(page) {
      this.loadClients(this.itemsPerPage, page, this.filter);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadClients(itemsPerPage, this.currentPage, this.filter);
    },
    loadClients(itemsPerPage, currentPage, search) {
      this.isBusy = true;
      let filters = '';

      if (this.lastOrder) {
        filters = this.getFilters();
      }
      getClubClients(itemsPerPage, currentPage, search, this.filters + filters)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.currentPage = currentPage;
          this.itemsPerPage = itemsPerPage;
          this.clientsList = (response.data['hydra:member']);
          this.items = [];

          for (const client of this.clientsList) {
            let item = {
              id: client.id,
              preview: client.identityPhoto ?? (client.user ? client.user.avatar : null),
              firstName: client.firstName,
              lastName: client.lastName,
              phoneNumber: client.phoneNumber,
              email: client.email,
              isLocked: client.isLocked,
              bookings: client.totalBookings ?? 0,
              subscriptionCardsAvailable: client.subscriptionCardsAvailable,
              credits: this.paymentTokensToString(client.paymentTokens),
              customerSubscription: this.subscriptionCardToString(client.subscriptionCardsAvailable),
              created: this.$moment(client.createdAt).format('DD/MM/YYYY - HH:mm'),
              actions: ['delete']
            }
            this.items.push(item);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    search(filter) {
      this.loadClients(this.itemsPerPage, this.currentPage, filter);
    },
    onRowClicked(scope) {
      this.$router.push({name: 'customer_details', params: {id: scope.id}});
    },
    onSubmit() {
      this.hide = !this.hide;
    },
    subscriptionCardToString(subscriptionCardsAvailable) {
      if ('undefined' === typeof subscriptionCardsAvailable) return onEmptyFields;
      if (null === subscriptionCardsAvailable) return onEmptyFields;
      return subscriptionCardsAvailable.reduce(function (list, currentCard, key) {
        if (key === 0) return list.concat(`${currentCard.name}`)
        return list.concat(`, ${currentCard.name}`)
      }, '')
    },
    paymentTokensToString(paymentTokens) {
      if ('undefined' === typeof paymentTokens) return onEmptyFields;
      if (null === paymentTokens || !paymentTokens.length) return onEmptyFields;
      return paymentTokens.reduce(function (list, currentToken, key) {
        if (key === 0) return list.concat(`${currentToken.total} x ${currentToken.name}`)
        return list.concat(`, ${currentToken.total} x ${currentToken.name}`)
      }, '')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
